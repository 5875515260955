import { useEffect, useMemo } from "react";
import { styles } from "./Events.styles";
import { FormRow } from "components/ui/form";
import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { useSelectedEvent } from "modules/events/eventHooks";
import { Controller, useFormContext } from "react-hook-form";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import Select from "react-select";

const CreateEventFormEventOptions: React.FC<CreateEventFormEventOptionsProps> = (
  props
) => {
  const { control, formState, setValue, getValues } = useFormContext();
  const { roundNumber } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedEvent = useSelectedEvent(currentFacilityId);

  const startDatePassed = eventStartDatePassed(getValues("startDate"));

  const roundOptionNames = useMemo(() => {
    return {
      ground: `roundInfo[${roundNumber}].ground`,
      sky: `roundInfo[${roundNumber}].sky`,
      windSpeed: `roundInfo[${roundNumber}].windspeed`,
      windDirection: `roundInfo[${roundNumber}].windDirection`,
    };
  }, [roundNumber]);

  useEffect(() => {
    if (!selectedEvent) {
      setValue(
        roundOptionNames.ground,
        roundConfigEnumsAsReactSelectOptions?.groundConditions[2].value
      );
      setValue(
        roundOptionNames.sky,
        roundConfigEnumsAsReactSelectOptions?.skyConditions[0].value
      );
      setValue(
        roundOptionNames.windSpeed,
        roundConfigEnumsAsReactSelectOptions?.eventsWindSpeed[1].value
      );
      setValue(
        roundOptionNames.windDirection,
        roundConfigEnumsAsReactSelectOptions?.windDirection[3].value
      );
    }
  }, [setValue, selectedEvent, roundOptionNames]);

  return (
    <>
      <FormRow header="Ground Conditions" fontSize={styles.formRowLabel}>
        <Controller
          name={roundOptionNames.ground}
          control={control}
          rules={{ required: "Ground conditions are required" }}
          defaultValue={
            getValues(roundOptionNames.ground)
              ? getValues(roundOptionNames.ground)
              : roundConfigEnumsAsReactSelectOptions?.groundConditions[2].value
          }
          render={({ field }) => (
            <Select
              {...field}
              options={roundConfigEnumsAsReactSelectOptions.groundConditions}
              value={roundConfigEnumsAsReactSelectOptions.groundConditions.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name={roundOptionNames.ground}
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Sky" fontSize={styles.formRowLabel}>
        <Controller
          name={roundOptionNames.sky}
          control={control}
          rules={{ required: "Sky is required" }}
          defaultValue={
            getValues(roundOptionNames.sky)
              ? getValues(roundOptionNames.sky)
              : roundConfigEnumsAsReactSelectOptions?.weather[0].value
          }
          render={({ field }) => (
            <Select
              {...field}
              options={roundConfigEnumsAsReactSelectOptions.weather}
              value={roundConfigEnumsAsReactSelectOptions.weather.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name={roundOptionNames.sky}
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Wind Speed" fontSize={styles.formRowLabel}>
        <Controller
          name={roundOptionNames.windSpeed}
          control={control}
          rules={{ required: "Wind speed is required" }}
          defaultValue={
            getValues(roundOptionNames.windSpeed)
              ? getValues(roundOptionNames.windSpeed)
              : roundConfigEnumsAsReactSelectOptions?.eventsWindSpeed[1].value
          }
          render={({ field }) => (
            <Select
              {...field}
              options={roundConfigEnumsAsReactSelectOptions.eventsWindSpeed}
              value={roundConfigEnumsAsReactSelectOptions.eventsWindSpeed.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
              menuPlacement="auto"
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name={roundOptionNames.windSpeed}
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
      <FormRow header="Wind Direction" fontSize={styles.formRowLabel}>
        <Controller
          name={roundOptionNames.windDirection}
          control={control}
          rules={{ required: "Wind direction is required" }}
          defaultValue={
            getValues(roundOptionNames.windDirection)
              ? getValues(roundOptionNames.windDirection)
              : roundConfigEnumsAsReactSelectOptions?.windDirection[3].value
          }
          render={({ field }) => (
            <Select
              {...field}
              options={roundConfigEnumsAsReactSelectOptions.windDirection}
              value={roundConfigEnumsAsReactSelectOptions.windDirection.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
              isDisabled={startDatePassed}
              menuPlacement="auto"
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name={roundOptionNames.windDirection}
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>
    </>
  );
};
export default CreateEventFormEventOptions;

type CreateEventFormEventOptionsProps = {
  roundNumber?: number;
};
