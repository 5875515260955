import { styles } from "./Events.styles";
import { AnimatePresence, motion } from "framer-motion";
import { useFormContext } from "react-hook-form";
import CreateEventFormEventOptions from "./CreateEventFormCourseOptions";
import CreateEventFormTeeOptions from "./CreateEventFormTeeOptions";
import { CgChevronDoubleUpO, CgChevronDoubleDownO } from "react-icons/cg";
import CreateEventFormCoursePinType from "./CreateEventFormCoursePinType";
import EventCourseAndHoles from "./EventCourseAndHoles";

const CreateEventFormRoundOptions: React.FC<CreateEventFormRoundOptionsProps> = (
  props
) => {
  const { expanded, setExpanded, i } = props;
  const isOpen = i === expanded;
  const roundNumber = i;

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const rounds = watch("rounds");
  const roundErrors = errors["roundInfo"]
    ? errors["roundInfo"][`${roundNumber}`]
    : "";

  return (
    <>
      <motion.header
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
        className={`${styles.eventRoundListItem} ${
          roundErrors ? styles.eventRoundListItemErrors : null
        }`}
      >
        Round {roundNumber + 1} {rounds > 1 ? `of ${rounds}` : ""}
        <span className={styles.eventRoundListItemIcon}>
          {isOpen ? <CgChevronDoubleUpO /> : <CgChevronDoubleDownO />}
        </span>
      </motion.header>
      <AnimatePresence initial={false}>
        <motion.section
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          className={isOpen ? "mb-6" : "hidden"}
        >
          <EventCourseAndHoles roundNumber={roundNumber} />
          <CreateEventFormCoursePinType roundNumber={roundNumber} />
          <CreateEventFormTeeOptions roundNumber={roundNumber} />
          <CreateEventFormEventOptions roundNumber={roundNumber} />
        </motion.section>
      </AnimatePresence>
    </>
  );
};

export default CreateEventFormRoundOptions;

type CreateEventFormRoundOptionsProps = {
  roundNumber?: number;
  i: number;
  expanded: number | boolean;
  setExpanded: React.Dispatch<React.SetStateAction<number | boolean>>;
};

CreateEventFormRoundOptions.defaultProps = {
  roundNumber: 1,
};
