import { ErrorMessage } from "@hookform/error-message";
import { FormRow } from "components/ui/form";
import Label from "components/ui/form/Label";
import Row from "components/ui/layout/Row";
import { Course, Hole } from "Models";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { ArrayHelper } from "utils/arrays/arrayHelpers";
import { customStyles } from "utils/ui/uiHelpers";
import React from "react";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import { useSelectedEvent } from "modules/events/eventHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";

type EventCourseAndHolesFormProps = {
  courses: Course[];
  holes: Hole[];
  roundNumber: number;
};

const EventCourseAndHolesForm: React.FC<EventCourseAndHolesFormProps> = (
  props
) => {
  const { courses, holes, roundNumber } = props;
  const { control, getValues, formState, setValue } = useFormContext();

  const courseOptions: { label: string; value: Course }[] = courses.map(
    (course) => {
      return { label: course.displayName || course.shortName, value: course };
    }
  );

  const holeAndCourseOptionNames = {
    holes: `roundInfo[${roundNumber}].holes`,
    courseName: `roundInfo[${roundNumber}].courseName`,
    courseId: `roundInfo[${roundNumber}].courseId`,
    roundNumber: `roundInfo[${roundNumber}].roundNumber`,
  };
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedEvent = useSelectedEvent(currentFacilityId);
  const startDatePassed = selectedEvent
    ? eventStartDatePassed(selectedEvent.startDate)
    : false;

  const eventCourseId = getValues(holeAndCourseOptionNames.courseId)
    ? getValues(holeAndCourseOptionNames.courseId).toString()
    : null;

  const handleMultiselectFieldChange = (
    e: { label: string; value: any }[],
    field: string
  ) => {
    setValue(
      field,
      e.map((x) => x.value)
    );
  };

  const handleCourseChange = (e: { label: string; value: Course }) => {
    setValue(holeAndCourseOptionNames.courseId, e.value.id);
    setValue(
      holeAndCourseOptionNames.roundNumber,
      (roundNumber + 1).toString()
    );
  };

  const selectFront9 = () =>
    setValue(holeAndCourseOptionNames.holes, ArrayHelper.fillWithRange(1, 9));

  const selectBack9 = () =>
    setValue(holeAndCourseOptionNames.holes, ArrayHelper.fillWithRange(10, 18));

  const selectAll18 = () =>
    setValue(holeAndCourseOptionNames.holes, ArrayHelper.fillWithRange(1, 18));

  return (
    <div>
      <FormRow header="Which course are you playing?">
        <Controller
          name={holeAndCourseOptionNames.courseId}
          control={control}
          rules={{ required: "Course selection is required" }}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={true}
              isMulti={false}
              options={courseOptions}
              value={courseOptions.find((option) =>
                eventCourseId
                  ? option.value.id === eventCourseId
                  : option.value.shortName === field.value
              )}
              onChange={handleCourseChange}
              styles={customStyles}
              isDisabled={startDatePassed}
              id={"eventCourseId" + roundNumber.toString()}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name={holeAndCourseOptionNames.courseId}
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </FormRow>

      <Row cssClasses={`pt-4`}>
        <Label
          name="Which holes do you want to play?"
          cssClasses="text-sm font-semibold text-gray-500 dark:text-gray-200"
        />
        {!startDatePassed && (
          <div className={`flex flex-row mb-5`}>
            <div
              onClick={selectFront9}
              className="border rounded-md border-brandGreen bg-brandGreen hover:bg-brandYellow text-sm text-white hover:text-black font-bold px-2 py-2 mt-2 mr-2 inline items-center hover:cursor-pointer"
            >
              Select Front 9
            </div>
            <div
              onClick={selectBack9}
              className="border rounded-md border-brandGreen bg-brandGreen hover:bg-brandYellow text-sm text-white hover:text-black font-bold px-2 py-2 mt-2 mr-2 inline items-center hover:cursor-pointer"
            >
              Select Back 9
            </div>
            <div
              onClick={selectAll18}
              className="border rounded-md border-brandGreen bg-brandGreen hover:bg-brandYellow text-sm text-white hover:text-black font-bold px-2 py-2 mt-2 inline items-center hover:cursor-pointer"
            >
              Select All 18
            </div>
          </div>
        )}
        <div></div>
        <Controller
          name={holeAndCourseOptionNames.holes}
          key={holeAndCourseOptionNames.holes}
          control={control}
          rules={{ required: "Hole selection is required" }}
          defaultValue={holes.map((hole) => hole.number)}
          render={({ field }) => (
            <MultiSelect
              {...field}
              options={holes.map((hole) => {
                return {
                  label: `${hole.number} Par: ${hole.par}`,
                  value: hole.number,
                };
              })}
              value={holes
                .filter((hole) =>
                  getValues(holeAndCourseOptionNames.holes)?.includes(
                    hole.number
                  )
                )
                .map((hole) => {
                  return {
                    label: hole.number.toString(),
                    value: hole.number,
                  };
                })}
              onChange={(e: { label: string; value: number }[]) =>
                handleMultiselectFieldChange(e, holeAndCourseOptionNames.holes)
              }
              labelledBy="Holes"
              hasSelectAll={true}
              selectAllLabel="Select/Unselect All Holes"
              disableSearch={true}
              disabled={startDatePassed}
              className={`${
                "eventHoles" + roundNumber.toString()
              } event dark:text-gray-200 dark:placeholder-black ${
                startDatePassed
                  ? "bg-gray-500 opacity-40 cursor-not-allowed"
                  : ""
              }`}
            />
          )}
        />
      </Row>

      <ErrorMessage
        errors={formState.errors}
        name={holeAndCourseOptionNames.holes}
        render={({ message }) => (
          <div className="text-sm text-red-500">{message}</div>
        )}
      />
    </div>
  );
};

export default EventCourseAndHolesForm;
