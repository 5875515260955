import axios from "axios";
import { FacilityInstalledUnityGames } from "Models";
import { getCurrentUserToken } from "modules/user";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import { RootState } from "StoreTypes";
import { facilityInstalledUnityGamesQuery } from "./simulatorQueries";

const urls = {
  sendMessage(simulatorId?: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${
      process.env.REACT_APP_BASE_URL
    }/api/simulators/${simulatorId ? simulatorId + "/" : ""}SendMessage`;
  },
  simCommand(command: string, simulatorId?: string | null): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${
      process.env.REACT_APP_BASE_URL
    }/api/simulators/${simulatorId ? simulatorId + "/" : ""}${command || ""}`;
  },
  timerCommand(
    command: string,
    simulatorId?: string | null,
    value?: string | null
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${
      process.env.REACT_APP_BASE_URL
    }/api/simulators/${
      simulatorId ? simulatorId + "/" : ""
    }timer?command=${command}${value ? "&value=" : ""}${value || ""}`;
  },
};

const headers = () => ({
  authorization: `Bearer ${getCurrentUserToken()}`,
  "Content-Type": "application/JSON",
});

export const simulatorControlType = {
  exit: "exit",
  end: "endround",
  shutdown: "SHUTDOWN",
  restart: "RESTART",
};

export const useSendMessageToSimulator = async (
  message: string,
  simulatorId?: string,
  simulatorIds?: string[]
) => {
  await axios
    .post(
      urls.sendMessage(simulatorId),
      { simulatorIds, message },
      {
        headers: headers(),
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const sendControlToSimulator = async (
  command: string,
  simulatorId?: string,
  simulatorIds?: string[]
) => {
  const endpoint = urls.simCommand(command, simulatorId);

  await axios
    .post(endpoint, { simulatorIds }, { headers: headers() })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const sendTimerToSimulator = async (
  command: string,
  simulatorId?: string,
  value?: string,
  simulatorIds?: string[]
) => {
  const endpoint = urls.timerCommand(
    command,
    simulatorId || null,
    value || null
  );

  await axios
    .post(endpoint, { simulatorIds }, { headers: headers() })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const useInstalledFacilityUnityGames = (
  facilityId: string
): UseDataInfo<FacilityInstalledUnityGames> => {
  const [queryState, refreshUnityGames] = useRequest(
    facilityInstalledUnityGamesQuery(facilityId)
  );

  const installedUnityGames = useSelector(
    (state: RootState): FacilityInstalledUnityGames => {
      let installedUnityGames =
        state.entities?.installedUnityGames?.byFacilityId?.[facilityId];
      return installedUnityGames;
    }
  );

  return [installedUnityGames, queryState, refreshUnityGames];
};

export const useInstalledSimulatorUnityGames = (
  facilityId: string,
  simulatorId: string
) => {
  const [facilityUnityGames] = useInstalledFacilityUnityGames(facilityId);

  const installedSimulatorUnityGames =
    facilityUnityGames?.simulators.find(
      (simulator) => simulator?.simulatorId === simulatorId
    )?.games || [];

  return installedSimulatorUnityGames;
};
