import { FacilityInstalledUnityGames } from "Models";
import { leagueQueryDefaultOptions } from "modules/leagues/leagueHelpers";
import { AppQueryConfig } from "QueryTypes";

const urls = {
  installedUnityGames(facilityId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/unity/facility/${facilityId}/installed_games`;
  },
};

export const facilityInstalledUnityGamesQuery = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.installedUnityGames(facilityId),
  queryKey: `facilityInstalledUnityGamesQuery:${facilityId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: FacilityInstalledUnityGames) => {
    return {
      installedUnityGames: {
        byFacilityId: {
          [facilityId]: responseJson,
        },
      },
    };
  },
  update: {
    installedUnityGames: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});
