import { useState } from "react";
import { CreateGroupPayload, Group } from "Models";
import { FormRow } from "components/ui/form";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import { useCreateGroup } from "modules/events/eventHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useFormContext } from "react-hook-form";
import {
  pendoTrackBasicEvent,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";

const CreateNewTour: React.FC = () => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const { setValue } = useFormContext();

  const [expandCreateTour, setExpandCreateTour] = useState(true);
  const [tourName, setTourName] = useState(() => "");

  const [createGroupQueryState, createGroup] = useCreateGroup(
    currentFacilityId
  );

  const addNewTour = () => {
    const newGroupObject: CreateGroupPayload = {
      Name: tourName,
      FacilityId: currentFacilityId,
    };

    createGroup(newGroupObject)?.then((response) => {
      const results: Group = response.body;
      setValue("groupId", results?.id);
    });
    setExpandCreateTour(!expandCreateTour);
    pendoTrackBasicEvent(ClubhouseEvents.CREATE_TOUR);
  };

  return (
    <div className="text-gray-600 dark:text-gray-50 my-4 text-sm">
      <span
        data-testid="createNewTourLink"
        onClick={() => setExpandCreateTour(!expandCreateTour)}
        className="hover:cursor-pointer"
      >
        {expandCreateTour ? <FaPlusSquare /> : <FaMinusSquare />}{" "}
        <span className={`text-sm pr-2`}>Add New Tour</span>
      </span>
      <FormRow header="Tour Name" hidden={expandCreateTour}>
        <input
          onChange={(event) => setTourName(event.target.value)}
          value={tourName}
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-50 text-sm leading-tight focus:outline-none focus:shadow-outline dark:bg-zinc-900"
        />
        <button
          onClick={addNewTour}
          className="bg-brandGreen text-white my-2 px-3 py-1 rounded shadow border border-white text-xs hover:bg-green-600 disabled:cursor-not-allowed disabled:opacity-50"
          type="button"
          disabled={createGroupQueryState.isPending}
        >
          <FaPlusSquare /> Add Tour
        </button>
      </FormRow>
    </div>
  );
};
export default CreateNewTour;
